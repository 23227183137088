.css-n3cyd2-MuiTableRow-root {
    display: flex;
    justify-content: space-evenly;
}
.css-1p0dxn5 {
    width:unset
}
.css-w614i4 .css-1kcj9bb{
    font-size: 0.75rem;
}

/* 
.css-1915xsu .css-1omj89j */