

.rhap_additional-controls{
  display:none
}

/* .css-lvl4a5-MuiDrawer-docked .MuiDrawer-paper{
  overflow: hidden;
}



.css-n4phvm-MuiList-root {
  overflow: auto;
  scrollbar-width: thin; 
  scrollbar-color: #ffffff #353333;
}

.css-n4phvm-MuiList-root::-webkit-scrollbar {
  width: 10px;
 
}

.css-n4phvm-MuiList-root::-webkit-scrollbar-track {
  background: #3b3a3a; 
}

.css-n4phvm-MuiList-root::-webkit-scrollbar-thumb {
  background-color: #ffffff; 
  border-radius: 5px;
}

.css-n4phvm-MuiList-root::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff; 
} */

