/* General styles for the privacy page */
.privacy-container {
  color: #000; /* Black text */
  line-height: 1.6; /* Improve readability */
  padding: 16px; /* Add padding inside the container */
}

.privacy-container h1 {
  font-size: 1.8rem; /* Smaller main heading */
  margin-bottom: 16px;
    color: #000; /* Black text */

}

.privacy-container h2,
.privacy-container h3 {
  font-size: 1.4rem; /* Adjust for smaller subheadings */
  margin-top: 24px;
  margin-bottom: 12px;
    color: #000; /* Black text */

}

.privacy-container p {
  font-size: 0.9rem; /* Smaller paragraph text */
  margin-bottom: 12px; /* Add spacing between paragraphs */
    color: #000; /* Black text */

}

.privacy-container a {
  color: #007BFF; /* Make links stand out */
    color: #000; /* Black text */

  text-decoration: none;
}

.privacy-container a:hover {
  text-decoration: underline; /* Add hover effect for links */
    color: #000; /* Black text */

}
