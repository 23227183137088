*{
    font-family: 'inter';
  
}

  
  /* .swal2-popup {
    border-radius: 15px; 
    background: #ffffff; 
  color:#0E4C75; 
 
  } */

    /* border: 1px solid #0E4C75; */

    .swal2-popup {
      border-radius: 15px; 
      border-color: white solid 3px;
      background: rgba(255, 255, 255,1);
      color:#0E4C75 ;
      font-size: 14px; 
      font-family: 'Inter', sans-serif;
    }

    .swal2-popup .swal2-deny {
      background-color:#66BB6A !important;
      width: 160px;
      margin-left: 8px;
  }

    .swal2-confirm {
      background-color: #c91456 !important; 
      color: white !important; 
      width: 160px;
      margin-right: 8px;

    }


